<!--
 * @Author: your name
 * @Date: 2021-07-27 16:03:10
 * @LastEditTime: 2021-10-24 09:10:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /vue-growing/src/views/About.vue
-->
<template>
  <div class="about">
    <h3>This is an usinfo page</h3>
    <h6>usCookie:{{ usCookie }}</h6>
    <h6>uskey:{{ uskey }}</h6>
    <h6>测试checkToken:{{ checkToken }}</h6>

    <div>
      <vue-qr ref="Qrcode" :text="urlsCode" qid="testQrId"></vue-qr>
    </div>
  </div>
</template>
<script>
import VueQr from "vue-qr";

export default {
  components: { VueQr },
  data() {
    return {
      usCookie: "",
      uskey: "",
      urlsCode: "",
    };
  },
  created() {
    this.urlsCode = window.location.href;
    this.uskey = localStorage.getItem("uskey");
    this.usCookie = localStorage.getItem("usCookie");
    this.checkToken = this.$sopen.checkToken(this.$route);
  },
  mounted() {},
  methods: {},
};
</script>